@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  font-family: "Ubuntu", sans-serif;
}

:root {
  --background: 0deg, 0%, 100%;
  --neutral-100: 0deg, 0%, 98%;
  --neutral-200: 0deg, 0%, 86%;
  --neutral-300: 0deg, 0%, 75%;
  --neutral-400: 0deg, 1%, 65%;
  --neutral-500: 0deg, 0%, 53%;
  --neutral-600: 0deg, 0%, 43%;
  --neutral-700: 0deg, 0%, 33%;
  --neutral-800: 0deg, 0%, 24%;
  --neutral-900: 0deg, 0%, 15%;
  --neutral-1000: 0deg, 0%, 6%;
  --danger-100: 0deg, 100%, 99%;
  --danger-200: 358deg, 76%, 90%;
  --danger-300: 1deg, 74%, 82%;
  --danger-400: 2deg, 76%, 72%;
  --danger-500: 5deg, 70%, 60%;
  --danger-600: 6deg, 58%, 48%;
  --danger-700: 8deg, 61%, 37%;
  --danger-800: 8deg, 64%, 26%;
  --danger-900: 10deg, 66%, 16%;
  --danger-1000: 12deg, 71%, 7%;
  --warning-100: 37deg, 80%, 98%;
  --warning-200: 36deg, 50%, 84%;
  --warning-300: 30deg, 66%, 72%;
  --warning-400: 27deg, 66%, 61%;
  --warning-500: 23deg, 59%, 51%;
  --warning-600: 20deg, 63%, 42%;
  --warning-700: 19deg, 66%, 33%;
  --warning-800: 19deg, 71%, 23%;
  --warning-900: 22deg, 80%, 14%;
  --warning-1000: 22deg, 93%, 6%;
  --tertiary-100: 56deg, 80%, 96%;
  --tertiary-200: 57deg, 63%, 71%;
  --tertiary-300: 54deg, 55%, 57%;
  --tertiary-400: 51deg, 47%, 49%;
  --tertiary-500: 47deg, 54%, 40%;
  --tertiary-600: 47deg, 56%, 32%;
  --tertiary-700: 48deg, 60%, 24%;
  --tertiary-800: 47deg, 64%, 17%;
  --tertiary-900: 47deg, 77%, 10%;
  --tertiary-1000: 43deg, 82%, 4%;
  --success-100: 67deg, 82%, 96%;
  --success-200: 78deg, 72%, 69%;
  --success-300: 82deg, 55%, 57%;
  --success-400: 84deg, 46%, 48%;
  --success-500: 86deg, 48%, 39%;
  --success-600: 87deg, 49%, 32%;
  --success-700: 87deg, 51%, 24%;
  --success-800: 88deg, 54%, 17%;
  --success-900: 86deg, 62%, 10%;
  --success-1000: 84deg, 71%, 4%;
  --primary-100: 114deg, 71%, 97%;
  --primary-200: 119deg, 66%, 79%;
  --primary-300: 122deg, 54%, 64%;
  --primary-400: 125deg, 43%, 52%;
  --primary-500: 127deg, 46%, 41%;
  --primary-600: 129deg, 50%, 33%;
  --primary-700: 131deg, 52%, 25%;
  --primary-800: 135deg, 57%, 17%;
  --primary-900: 142deg, 69%, 10%;
  --primary-1000: 146deg, 73%, 4%;
  --secondary-100: 180deg, 60%, 98%;
  --secondary-200: 185deg, 69%, 78%;
  --secondary-300: 189deg, 60%, 66%;
  --secondary-400: 193deg, 49%, 57%;
  --secondary-500: 197deg, 43%, 48%;
  --secondary-600: 199deg, 47%, 39%;
  --secondary-700: 203deg, 48%, 31%;
  --secondary-800: 205deg, 52%, 23%;
  --secondary-900: 209deg, 55%, 15%;
  --secondary-1000: 213deg, 59%, 7%;
  --info-100: 255deg, 100%, 99%;
  --info-200: 223deg, 73%, 90%;
  --info-300: 225deg, 68%, 81%;
  --info-400: 229deg, 67%, 74%;
  --info-500: 231deg, 63%, 66%;
  --info-600: 233deg, 59%, 58%;
  --info-700: 234deg, 54%, 49%;
  --info-800: 236deg, 63%, 38%;
  --info-900: 241deg, 61%, 25%;
  --info-1000: 248deg, 68%, 11%;
}
