@import "https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap";
body {
  font-family: Ubuntu, sans-serif;
}

:root {
  --background: 0deg, 0%, 100%;
  --neutral-100: 0deg, 0%, 98%;
  --neutral-200: 0deg, 0%, 86%;
  --neutral-300: 0deg, 0%, 75%;
  --neutral-400: 0deg, 1%, 65%;
  --neutral-500: 0deg, 0%, 53%;
  --neutral-600: 0deg, 0%, 43%;
  --neutral-700: 0deg, 0%, 33%;
  --neutral-800: 0deg, 0%, 24%;
  --neutral-900: 0deg, 0%, 15%;
  --neutral-1000: 0deg, 0%, 6%;
  --danger-100: 0deg, 100%, 99%;
  --danger-200: 358deg, 76%, 90%;
  --danger-300: 1deg, 74%, 82%;
  --danger-400: 2deg, 76%, 72%;
  --danger-500: 5deg, 70%, 60%;
  --danger-600: 6deg, 58%, 48%;
  --danger-700: 8deg, 61%, 37%;
  --danger-800: 8deg, 64%, 26%;
  --danger-900: 10deg, 66%, 16%;
  --danger-1000: 12deg, 71%, 7%;
  --warning-100: 37deg, 80%, 98%;
  --warning-200: 36deg, 50%, 84%;
  --warning-300: 30deg, 66%, 72%;
  --warning-400: 27deg, 66%, 61%;
  --warning-500: 23deg, 59%, 51%;
  --warning-600: 20deg, 63%, 42%;
  --warning-700: 19deg, 66%, 33%;
  --warning-800: 19deg, 71%, 23%;
  --warning-900: 22deg, 80%, 14%;
  --warning-1000: 22deg, 93%, 6%;
  --tertiary-100: 56deg, 80%, 96%;
  --tertiary-200: 57deg, 63%, 71%;
  --tertiary-300: 54deg, 55%, 57%;
  --tertiary-400: 51deg, 47%, 49%;
  --tertiary-500: 47deg, 54%, 40%;
  --tertiary-600: 47deg, 56%, 32%;
  --tertiary-700: 48deg, 60%, 24%;
  --tertiary-800: 47deg, 64%, 17%;
  --tertiary-900: 47deg, 77%, 10%;
  --tertiary-1000: 43deg, 82%, 4%;
  --success-100: 67deg, 82%, 96%;
  --success-200: 78deg, 72%, 69%;
  --success-300: 82deg, 55%, 57%;
  --success-400: 84deg, 46%, 48%;
  --success-500: 86deg, 48%, 39%;
  --success-600: 87deg, 49%, 32%;
  --success-700: 87deg, 51%, 24%;
  --success-800: 88deg, 54%, 17%;
  --success-900: 86deg, 62%, 10%;
  --success-1000: 84deg, 71%, 4%;
  --primary-100: 114deg, 71%, 97%;
  --primary-200: 119deg, 66%, 79%;
  --primary-300: 122deg, 54%, 64%;
  --primary-400: 125deg, 43%, 52%;
  --primary-500: 127deg, 46%, 41%;
  --primary-600: 129deg, 50%, 33%;
  --primary-700: 131deg, 52%, 25%;
  --primary-800: 135deg, 57%, 17%;
  --primary-900: 142deg, 69%, 10%;
  --primary-1000: 146deg, 73%, 4%;
  --secondary-100: 180deg, 60%, 98%;
  --secondary-200: 185deg, 69%, 78%;
  --secondary-300: 189deg, 60%, 66%;
  --secondary-400: 193deg, 49%, 57%;
  --secondary-500: 197deg, 43%, 48%;
  --secondary-600: 199deg, 47%, 39%;
  --secondary-700: 203deg, 48%, 31%;
  --secondary-800: 205deg, 52%, 23%;
  --secondary-900: 209deg, 55%, 15%;
  --secondary-1000: 213deg, 59%, 7%;
  --info-100: 255deg, 100%, 99%;
  --info-200: 223deg, 73%, 90%;
  --info-300: 225deg, 68%, 81%;
  --info-400: 229deg, 67%, 74%;
  --info-500: 231deg, 63%, 66%;
  --info-600: 233deg, 59%, 58%;
  --info-700: 234deg, 54%, 49%;
  --info-800: 236deg, 63%, 38%;
  --info-900: 241deg, 61%, 25%;
  --info-1000: 248deg, 68%, 11%;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.flex {
  display: flex;
}

.h-screen {
  height: 100vh;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.rounded-lg {
  border-radius: .5rem;
}

.bg-orange-950\/50 {
  background-color: #43140780;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-10 {
  padding: 2.5rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/80 {
  color: #fffc;
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

/*# sourceMappingURL=index.5428db64.css.map */
